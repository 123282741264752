:root {
    --control-background-color: #f4f9f0;
    --control-border-color: #88c656;
    --border-color-gray: #d5d5d5;
}

html {
    height: 100%;
}

body {
    background-color: #3d3e3f !important;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    height: 100%;
    overflow: hidden;
}

h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px;
}

h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 15.4px;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px;
}

blockquote {
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 30px;
}

pre {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5714px;
}

#overlay-loader[data-show="true"] {
    display: block !important;
}

#overlay-loader {
    background: #ffffff;
    color: #666666;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: 25%;
    opacity: .80;
}

    #overlay-loader > .spinner {
        margin: 0 auto;
        height: 40px;
        width: 40px;
        animation: rotate 0.8s infinite linear;
        border: 3px solid #0973ba;
        border-right-color: transparent;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        position: fixed;
    }

.overlay-loader-text {
    text-align: center;
    font-weight: bold;
    font-size: 110%;
    color: #363535;
    position: relative;
    top: 10%
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*min-height: 100vh;*/
    height: 100%;
    background-color: #f0f0f0 !important;
}

.app-header {
    height: 52px;
    background-color: #0873ba;
    padding: 6px;
    z-index: 2;
    position: relative;
    display: flex;
}

.popover {
    border: 1px solid var(--headerBgColor);
}

.popover-header {
    font-size: 12px;
    font-weight: bold;
    margin: 1px;
}

.headerForeColor {
    color: var(--headerForeColor) !important;
}

.ddl-icon {
    margin-right: 10px;
    color: var(--headerBgColor);
    width: 15.75px;
}

.btn-icon {
    margin-right: 10px;
}

.account-menu i {
    font-size: 14px;
    margin-right: 14px;
}

.account-menu i.fa-arrow-up-right-from-square {
    font-size: 12px;
}

.btn-primary {
    /*    background-color: var(--bottonBgColor) !important;
    border: 1px solid var(--bottonBorderColor);
    border-color: var(--bottonBorderColor) !important;*/
    background-color: #88c656 !important;
    border-color: #88c656 !important;
}

.btn-primary, .btn-light, .btn-info {
    border-radius: 50px;
    min-width: 150px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 50px !important;
}

    .btn-primary:hover {
        color: #fff;
        background-color: #0973ba;
        border-color: #0973ba;
    }

/*-------------Font Face -------------*/

/* @font-face {
    font-family: 'Journal';
    src: url('../fonts/journal.eot');
    src: url('../fonts/journal.eot?#iefix') format('embedded-opentype'), url('../fonts/journal.woff') format('woff'), url('../fonts/journal.ttf') format('truetype'), url('../fonts/journal.svg#JournalRegular') format('svg');
    font-weight: normal;
    font-style: normal;
} */

.no-padding-left {
    padding-left: 0px !important;
}

.no-padding-right {
    padding-right: 0px !important;
}

.no-padding-top {
    padding-top: 0px !important;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.no-padding {
    padding: 0px 0px 0px 0px !important;
}


.no-margin {
    margin: 0 !important;
}

.width-100 {
    width: 100%;
}

.width-98 {
    width: 98%;
}

.font-style-italic {
    font-style: italic
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-left-50 {
    margin-left: 50px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.padding-left-6 {
    padding-left: 6px;
}

.height-100 {
    height: 100%
}

.popover {
    border: 1px solid var(--bottonBgColor) !important;
}

._loading_overlay_wrapper {
    min-height: 100vh;
}

._loading_overlay_overlay {
    margin-top: -52px;
}
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d6cece;
    border-radius: 8px;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #777;
    }

.scroller {
    scrollbar-width: thin;
}


/* Layout - Start */

header .company-name {
    color: var(--headerForeColor);
    font-size: 24px;
    margin-left: 10px;
}

.logo-img {
    height: 40px;
}

.content-wrapper {
    background-color: #F0F0F0;
    min-height: calc(100% - 52px);
}


.header-left-container {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: auto;
    padding-left: 6%;
}

.header-right-container {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-left: auto;
    min-width: 136px;
    padding-right: 6%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.header-mydownload, .header-contact-info, .header-logout, .header-account, .header-taxYear, .header-taxReturn, .header-home {
    color: var(--headerForeColor);
    cursor: pointer;
    margin-left: 15px;
    padding-left: 5px;
}

.header-home i {
    margin-right: 5px;
}

    .header-contact-info a {
        color: var(--headerForeColor);
    }

        .header-contact-info a svg {
            max-width: 16px;
            max-height: 16px;
            margin-right: 5px;
        }

    .header-contact-info:hover, .header-logout:hover {
        text-decoration: underline;
    }

    .header-taxYear a svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: 2px;
    }

    .header-account a svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: 4px;
    }

.mail {
    font-size: 14px;
    color: #88c656;
    font-weight: bold;
}

    .mail a:hover {
        cursor: pointer;
    }

.account-menu, .taxYear-menu, .taxReturn-menu {
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
}

.header-account a::after
/*, .header-taxYear a::after, .header-taxReturn a::after*/ {
    content: "";
    width: 0px;
    height: 1px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #ffffff;
    position: absolute;
    margin-left: 5px;
    margin-top: 8px;
    z-index: 100;
}

.sidebarIconToggle, #sidebarMenu {
    display: none;
}

#sidebarMenu {
    height: 100%;
    position: fixed;
    left: 0;
    width: 225px;
    margin-top: 30px;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
    /*background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);*/
    background-color: var(--headerBgColor);
    overflow: scroll;
    z-index: 999;
}

.sidebarMenuInner {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
}

    .sidebarMenuInner li {
        list-style: none;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        padding: 20px;
        cursor: pointer;
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }

        .sidebarMenuInner li span {
            display: block;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.50);
        }

        .sidebarMenuInner li a {
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
            text-decoration: none;
        }

input[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(0);
}

#openSidebarMenu {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
}

.sidebarIconToggle {
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    top: 10px;
    left: 15px;
    height: 22px;
    width: 22px;
}

.spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
}

.horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

.diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
}

.diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 3px;
}

input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
}

input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
}

input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
}


.show {
    display: block !important;
}

.sidebar {
    width: 225px !important;
    min-height: calc(100vh - 42px);
    user-select: none;
}

.navbar-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1.5rem !important;
}

.navbar-nav {
    float: left;
    margin: 0;
}

    .navbar-nav > li {
        float: left;
        border-top: 1px solid #fff;
    }

        .navbar-nav > li:last-child {
            border-bottom: 1px solid #fff;
        }

.dropup, .dropright, .layout-dropdown, .dropleft {
    position: relative;
}

.sidebar .nav-item .nav-link {
    display: block;
    text-align: left;
    padding: 1rem;
    width: 225px;
}

.sidebar .nav-item .nav-link {
    color: #fff;
}

.sidebar .nav-item.layout-dropdown .layout-dropdown-toggle::after {
    display: block;
}

/*.navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle::after {
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: '\f105';
    font-family: 'Font Awesome 5 Free';
    color: #fff;
}*/

navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle span {
    margin: 10px;
}

.sidebar .nav-item .nav-link span {
    font-size: 1.5rem;
    display: inline;
}

.nav-link span {
    color: #fff;
    text-shadow: none;
}

/*.navbar-nav .nav-item.layout-dropdown.show .layout-dropdown-toggle::after {
    content: '\f107';
}*/

.layout-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.layout-dropdown-menu {
    box-shadow: none;
    position: static !important;
    margin-left: 30px;
    top: 0;
    background-color: transparent;
    border: none;
}

.navbar-nav .layout-dropdown-menu {
    position: static;
    float: none;
}

.sidebar .nav-item .layout-dropdown-menu {
    -webkit-transform: none !important;
    transform: none !important;
    left: calc(50px + 0.5rem) !important;
    margin: 0;
}

.layout-dropdown-menu .layout-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.50rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #fff;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}

.layout-dropdown-menu.show {
    padding-top: 0px;
}

a.active.layout-dropdown-item {
    text-decoration: underline;
}
/* Layout - END */
/* Welcome Page - START */
.welcome-page {
    padding-left: 36% !important;
    padding-right: 36% !important;
    padding-top: 30px !important;
    height: 90vh;
    overflow: auto;
}

.welcome-page-container {
    border: 2px solid var(--headerBgColor);
    /*min-height: 80vh;*/
    border-radius: 6px;
    padding: 15% 10%;
    background-color: var(--coverPageBgColor);
    /*box-shadow: 2px 0 2px 1px var(--headerBgColor);*/
    z-index: 2;
    cursor: pointer;
    color: var(--coverPageForeColor);
}

.welcome-page-content-box {
    border: 1px solid #bbb;
    border-radius: 1px;
    padding: 5% 10%;
    text-align: center;
    background-color: #fff;
}


.margin-top-50 {
    margin-top: 50px
}

.margin-top-10 {
    margin-top: 10px
}



.prepared-by-img {
    height: 90px;
    max-width: 90%;
    /* height: 100%; */
}

.prepared-by-company-name {
    font-size: x-large;
    font-weight: 600;
}

.tax-year-header {
    font-size: 22px;
    font-weight: 600;
}

.prepared-by-title {
    font-size: 12px;
}

.welcome-page-navigator {
    position: absolute;
    top: 62px;
    right: -92px;
    background-color: yellow;
    font-size: 12px;
    padding: 4px 16px;
    transform: rotate(90deg);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    z-index: 0;
    width: 140px;
    text-align: center;
    border: 2px solid var(--headerBgColor);
    border-bottom: 0;
}

    .welcome-page-navigator a {
        color: black;
    }

        .welcome-page-navigator a:hover {
            text-decoration: none;
            color: black;
        }
/* For larger tablets iPad Pro And Tab7 */

@media only screen and (max-width: 1038px) {
    .cp-welcome-page {
        width: 90%;
        max-width: 1000px;
        margin-top: 10%;
    }

    .cp-welcome-page-container {
        height: calc(100vh - 200px);
        background-size: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    }

    .cp-company-info-container {
        position: unset;
        margin-top: 15%;
    }

        .cp-company-info-container img {
            height: 100%;
        }

    .cp-tax-client-info-container {
        height: 25%;
        width: 70%;
        margin: 15%;
        position: unset;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
    }

    .cp-tax-client-info-text {
        margin: auto 20px auto auto;
        padding-top: 6%;
        /* height: 75%; */
    }

    .cp-tax-client-info-label {
        margin: auto auto auto 15px;
        font-size: 22px;
    }

    .cp-tax-year-header {
        font-size: 22px;
    }

    .cp-tax-client-name {
        /* margin-bottom: 30px; */
        font-size: 24px;
        margin-top: 30px;
    }

    .cp-btn-organizer-continue {
        position: unset;
        margin-left: auto;
        margin-right: auto;
        font-size: 24px;
        width: 180px;
    }
}

/* For Smaller Tablets iPad mini  */
@media only screen and (max-width: 769px) {
    .cp-welcome-page {
        width: 90%;
        max-width: 780px;
        margin-top: 10%;
    }

    .cp-welcome-page-container {
        height: calc(100vh - 200px);
        background-size: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 80px;
    }

    .cp-company-info-container {
        position: unset;
        margin-top: 15%;
    }

        .cp-company-info-container img {
            height: 100%;
        }

    .cp-tax-client-info-container {
        height: 25%;
        width: 70%;
        margin: 15%;
        position: unset;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
    }

    .cp-tax-client-info-text {
        margin: auto 20px auto auto;
        padding-top: 6%;
    }

    .cp-tax-client-info-label {
        margin: auto auto auto 10px;
        font-size: 18px;
    }

    .cp-tax-year-header {
        font-size: 18px;
    }

    .cp-tax-client-name {
        /* margin-bottom: 30px; */
        font-size: 20px;
        margin-top: 30px;
    }

    .cp-btn-organizer-continue {
        position: unset;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        width: 160px;
    }
}
/* Welcome Page - END */

/* SSN Page - START */

.ssn-page, .otp-page {
    padding-left: 16% !important;
    padding-right: 16% !important;
    padding-top: 30px !important;
    height: 90vh;
    overflow: auto;
}

.ssn-page-container, .otp-page-container {
    border-radius: 6px;
    padding: 12% 10%;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
}

    .ssn-page-container input, .otp-page-container input {
        border: 1px solid var(--control-border-color);
        border-radius: 5px;
        background-color: var(--control-background-color);
        width: 80px;
        height: 40px;
        color: #888;
        padding: 5px;
        font-weight: bold;
        font-size: 16px;
    }

    .otp-page-container input {
        width: 160px;
    }

.ssn-page-container-label-div {
    display: inline-block
}

.ssn-page-container-input-div {
    display: inline-block;
    vertical-align: bottom
}

.access-code-expiry-info {
    margin-left: 50px !important;
    font-style: italic;
}

.error-container {
    color: red;
    text-align: center;
}
/* SSN Page - END */

/* Summary Page Start */

.content-top, .content-left, .content-wrapper-full {
    margin-left: 8%;
}

.content-top, .content-left, .content-right, .content-wrapper-full {
    /*-webkit-box-shadow: 0px 2px 0px 0px #e5e5e5;
    -moz-box-shadow: 0px 2px 0px 0px #e5e5e5;
    box-shadow: 0px 2px 0px 0px #e5e5e5;*/
    /*min-height: 380px;*/
}

.content-right, .content-wrapper-full {
    padding-right: 125px;
    margin-top: 0px !important;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    height: 98%;
}

.content-left > div {
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}



.estimated-voucher-container {
    height: 50%;
    margin-top: 3%;
    margin-right: 0px !important;
    padding-right: 0px !important;
}

    .payment-refund-container .title, .estimated-voucher-container .title, .tax-payment-container .title, .payment-instruction-container .title {
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-left: 10px;
    }

    .payment-refund-container .body, .estimated-voucher-container .body {
        padding: 0px 10px;
        overflow: auto;
        height: 92%;
    }



.sub-section .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding: inherit;
}

.bold {
    color: #000;
}

.fllft {
    float: left;
}

.green {
    color: #88c656;
}

.red {
    color: #e00d0d;
}

.flrt {
    float: right;
}

.semibold {
    font-weight: 600;
}

.currencytext {
    text-align: right;
    padding-right: 0px;
    font-family: monospace;
}

.content-left .section-title {
    color: #0973ba;
    font-weight: bold;
    font-size: 15px;
    clear: both;
}

.content-left .preSelect:first-of-type {
    clear: both;
    margin: 20px 1px;
}

.preSelect {
    clear: both;
    margin: 40px 1px;
}

.sub-section {
    clear: both;
}

.total-amount-green {
    border-top: 1px solid #88c656;
    font-size: 15px;
    font-weight: 700;
    width: 40% !important;
}

.total-amount-red {
    border-top: 1px solid #e00d0d;
    font-size: 15px;
    font-weight: 700;
    width: 40% !important;
}

.content-left .sub-section div {
    width: 50%;
    position: relative;
}

.content-left .sub-section .authority {
    font-size: 14px;
    font-weight: normal;
}

.content-left .sub-section .amount {
    font-size: 14px;
    font-weight: 400;
}

.content-right .welcome {
    font-size: 22px;
    text-align: center;
    margin-bottom: 3%;
    font-weight: 600;
}

.margin-left-1 {
    margin-left: 1%;
}

.content-right .welcome-intro {
    font-size: 14px;
    text-align: center;
    margin-bottom: 3%;
}

.step-img-btn {
    text-align: center;
    width: 100%;
}

    .step-img-btn > div {
        display: inline-block;
        float: right;
    }

        .step-img-btn > div > svg {
            height: 92px;
            margin: 1% 3%;
            cursor: pointer;
        }

    .step-img-btn .img-caption {
        font-size: 13px;
        font-weight: bold;
        color: #0973ba;
        margin-top: 10px;
    }

.summary-start-step-container {
    text-align: center;
    width: 100%;
}

    .summary-start-step-container button {
        font-size: 14px;
        font-weight: bold;
        border-radius: 50px !important;
        margin: 6% 0%;
    }

.icon---summary-review-documents {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.icon---summary-sign-documents {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.icon---distribute-k1s {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.icon---summary-make-payments {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.center-loading-text {
    position: absolute;
    font-size: 1.5em;
    top: 40vh;
    left: 40vw;
    z-index: 99;
    background-color: #fff;
}

/*Summary Page End*/
@media (max-width: 800px) {
    /* .app-container {
        position: fixed;
        width: 100%;
        top: 0;
    } */
    
    .content-wrapper-full .content-select-sign-buttons {
        display: block !important;
        margin-top: 10px;
        height: calc(100% - 80px) !important;
    }

    .app-header {
        height: 36px;
        padding: 6px;
    }

    header .company-name {
        font-size: 18px;
    }

    .logo-img {
        height: 24px;
    }

    .welcome-page {
        padding-left: 10% !important;
        padding-right: 20% !important;
    }

    .welcome-page-container {
        padding: 10%;
    }

    .header-contact-info {
        padding: 2px;
    }

    .header-left-container {
        padding-left: 40px;
    }

    .header-contact-info, .header-logout, .header-account {
        padding: 2px 6px;
        margin-left: 0;
    }

    .ssn-page, .otp-page {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .ssn-page-container, .otp-page-container {
        padding-bottom: 75px !important;
    }

    .access-code-expiry-info {
        margin-left: 0px !important;
    }

    .header-account, .header-taxYear, .header-taxReturn {
        display: block;
    }

    .content-top, .content-left, .content-right, .pay-content-left, pay-content-top .content-wrapper-full {
        overflow-x: hidden;
    }

    .payment-refund-container, .estimated-voucher-container {
    }

    .steps-wrapper {
        /*overflow: auto;*/ /* Commented because of  bug 41444*/
    }

    .imageBorderEsign {
        margin: 24px !important;
    }

    .imageBorderManualSignText {
        margin: 9px !important;
    }

    .information-popup {
        left: 20% !important;
    }
}

/* Sign Page - Start */
.imageBorderEsignSelect {
    border: 2px solid #88c656;
    border-radius: 16px;
    opacity: 0.7;
    transition: 0.3s;
    background-color: #d8d8d8 !important;
    width: 216px;
    height: 141px;
    text-align: center;
    display: block;
    margin: 0 auto;
}

    .imageBorderEsignSelect:hover {
        opacity: 1;
    }

.imageBorderManualSign {
    margin: 24px 0px 0px 15px;
}

.tab {
    display: inline-block;
    margin-left: 0px;
}

.imageTaxpayerSigning {
    margin: 8% 0% 0% 0%;
}



.signPageDesc {
    width: 100%;
}

.font-weight-normal-bold {
    font-weight: 600;
    padding-right: 5px;
}

.font-weight-bold {
    font-weight: bold;
}
/* Sign Page - End */
@media (min-width: 800px) and (max-width: 1024px) {
    .content-left {
        margin-left: 3%;
    }

    .steps-wrapper {
        overflow: auto;
    }

    .step-img-btn > div.col-lg-4 {
        width: 33%;
    }

    .step-img-btn > div.col-lg-3 {
        width: 25%;
    }

    .information-popup {
        left: 30% !important;
    }
}
/* Esign page Start */
.content-wrapper-full {
    border-radius: 5px;
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
    padding: 15px;
}

    .content-wrapper-full > div {
        background-color: #fff;
    }

    .content-wrapper-full .content-header {
        font-size: large;
        font-weight: 500;
        padding: 20px;
    }

    .content-wrapper-full .content-paragraph {
        padding: 0px 20px 10px;
    }

    .content-wrapper-full input[type=checkbox] {
        display: inline;
    }

    .content-wrapper-full input[type=text] {
        border: 2px solid var(--border-color-gray);
        border-radius: 4px;
        height: 30px;
        width: 200px;
    }

    .content-wrapper-full .scrollable-menu {
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
        display: inline !important;
    }

    .content-wrapper-full .dropdown-toggle.btn.btn-success {
        color: var(--control-border-color);
        background-color: var(--control-background-color);
        border-color: var(--control-border-color);
        border-radius: 4px !important;
        width: 100px;
        margin-bottom: 5px;
        text-align: left;
    }

        .content-wrapper-full .dropdown-toggle.btn.btn-success::after {
            position: absolute;
            right: 10px;
            top: 4px;
        }

    .content-wrapper-full .content-select-sign-buttons {
        height: calc(100% - 62px);
        display: inline-flex;
        align-items: center;
    }

    .content-wrapper-full .esign-div {
        /* width: 50%;*/
        text-align: center;
        display: block;
        /*padding-left: 21%;*/
    }

        .content-wrapper-full .esign-div .imageBorderEsignSelect .imageBorderEsign {
            color: #1a8fbf;
            /*margin: 65px;*/
        }

    .content-wrapper-full .content-select-sign-buttons .esign-div .signPageSpan1 {
        position: relative;
        display: inline-flex;
    }

    .content-wrapper-full .manusign-div {
        /*width: 50%;*/
        text-align: center;
        display: block;
    }

        .content-wrapper-full .manusign-div .imageBorderEsignSelect .imageManualSigner {
            margin: 0%;
            width: 93%;
        }

        .content-wrapper-full .manusign-div .imageBorderEsignSelect .imageBorderManualSignText {
            color: #1a8fbf;
            margin: 54px;
        }

        .content-wrapper-full .manusign-div .signPageSpan2 {
            position: relative;
            /*margin: 1% 0% 0% 1%;*/
        }

.padding-full-20 {
    padding: 20px;
}

.margin-right-15p {
    margin-right: 15%;
}

.YearMonthDay {
    display: inline;
}

    .YearMonthDay .select-control {
        display: inline;
    }

        .YearMonthDay .select-control .show.dropdown {
            display: inline !important;
        }

        .YearMonthDay .select-control .dropdown {
            display: inline;
        }

.dropdown-menu {
    min-width: auto !important;
}

.consent-checkbox {
    padding: 20px;
}

.spouse-header {
    color: #1a8fbf;
    padding: 20px;
}

.signing-page-main-container {
    width: 100%;
}

.kba-questions {
    margin-top: 20px;
    font-weight: bold;
}

.react-checkbox-tree:not(.rct-native-display) .rct-checkbox {
    display: none;
}

.btn-bottom-right {
    bottom: 0;
    right: 0;
    position: absolute;
    margin: 10px;
}

.black-border-line {
    border: 1px solid var(--border-color-gray);
}

.dib {
    display: inline-block;
}

.width-50 {
    width: 50%;
}

.text-align-right {
    text-align: right;
}
/* Esign page End */
#summary-review {
    height: 100%;
}

.payment-refund-print {
    float: right;
    font-size: larger;
    color: #0973ba;
    cursor: pointer;
    margin-right: 10px;
}

#preparer_message {
    height: 100%;
}

.preparerMessage {
    margin-top: 10px;
    border: 1px solid #e9e9e9;
    height: calc(100% - 55px) !important;
    overflow-y: auto;
    padding: 20px;
    position: relative;
    font-weight: 500;
}


.preparerMessageContainer {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
}


.preparerMessagePrint {
    float: right;
    color: #0973ba;
    font-size: x-large;
    cursor: pointer;
}

.reviewTab {
    float: left;
    width: 15%;
    height: 88%;
    border-right: 2px solid darkgray;
    background-color: white;
}

.reviewContainerBody {
    height: 88% !important;
    background-color: #e0e0e0;
    border-right: 2px solid;
}

#review-tab-navbar {
    margin: 20px 0px;
    color: #88c656;
    font-weight: 700;
    width: auto;
    padding-left: 18px;
    font-size: larger
}


.review-left-panel {
    margin: 0;
    padding: 0;
    overflow: unset !important;
}

.review-left-pdf-viewer {
    padding: 0;
}

.reviewContainer {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 5px;
    width: 96%;
    height: 88%;
    margin-left: 20px;
}

.right-pointer {
    position: relative;
    background: var(--headerBgColor);
    height: 45px;
    line-height: 40px;
    margin-bottom: 20px;
    vertical-align: middle;
    color: white !important;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
    width: 85% !important;
}

.arrow-right:before {
    content: "";
    position: absolute;
    right: -30px;
    top: -2px;
    border-top: 26px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 25px solid transparent;
    width: 31px;
}

.cursor-pointer {
    cursor: pointer;
}

.arrow-right:after {
    content: "";
    position: absolute;
    right: -31px;
    top: 0px;
    border-top: 24px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 23px solid var(--headerBgColor);
    width: 32px;
    color: white;
    z-index: 9;
}

#document-review {
    margin-left: 25px !important;
}

.review-container {
    height: 100%;
    padding: 0;
}

.review-container-body {
    display: flex;
    border-bottom: 2px solid darkgray;
    background: white;
    height: calc(100% - 55px);
}

.review-container-title {
    height: 55px;
    border-bottom: 2px solid darkgray;
    padding: 12px 0px 12px 30px;
    font-weight: bold;
    font-size: x-large;
    background: white;
}

.attachment-viewer {
    padding-top: 4%;
    padding-left: 14%;
}

.attachment-file-viewer {
    cursor: pointer;
    color: #4CAF50;
    font-weight: 700;
    padding-top: 10px;
}

#attachment-table {
    font-size: larger;
}

.attachment-ZipBtn {
    margin-top: 18%;
    margin-left: 7%;
}

.buttonDownload {
    display: inline-block;
    position: relative;
    padding: 10px 25px;
    background-color: var(--headerBgColor);
    color: white;
    font-family: sans-serif;
    text-decoration: none;
    font-size: 0.9em;
    text-align: center;
    text-indent: 15px;
    cursor: pointer
}

    .buttonDownload:hover {
        background-color: #333;
        color: white;
    }

    .buttonDownload:before, .buttonDownload:after {
        content: ' ';
        display: block;
        position: absolute;
        left: 15px;
        top: 52%;
    }

    /* Download box shape  */
    .buttonDownload:before {
        width: 14px;
        height: 2px;
        border-style: solid;
        border-width: 0 2px 2px;
    }

    /* Download arrow shape */
    .buttonDownload:after {
        width: 0;
        height: 0;
        margin-left: 3px;
        margin-top: -7px;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: transparent;
        border-top-color: inherit;
        animation: downloadArrow 2s linear infinite;
        animation-play-state: paused;
    }

    .buttonDownload:hover:before {
        border-color: #4CC713;
    }

    .buttonDownload:hover:after {
        border-top-color: #4CC713;
        animation-play-state: running;
    }

/* keyframes for the download icon anim */
@keyframes downloadArrow {
    /* 0% and 0.001% keyframes used as a hackish way of having the button frozen on a nice looking frame by default */
    0% {
        margin-top: -7px;
        opacity: 1;
    }

    0.001% {
        margin-top: -15px;
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        margin-top: 0;
        opacity: 0;
    }
}

.colorBlue {
    color: #1a8fbf;
}

@media(min-width:315px) and (max-width:421px) {
    .esign-div {
        margin-right: 10%;
        padding: 0% 0% 0% 0%
    }

    body {
        overflow: auto;
    }

    .information-popup {
        left: 0 !important;
    }
}

.myaccount .modal-header {
    padding: 15px;
}

.myaccount .modal-title {
    color: var(--headerBgColor);
    font-size: 14px;
    font-weight: bold;
}

.myaccount .modal-body {
    position: relative;
    padding: 15px;
    padding-left: 20px;
}

.myaccount .modal-footer {
    padding: 15px;
    text-align: right;
}

.myaccount .modal-content {
    width: 130%;
    margin-left: -50px;
}

.myaccount-text {
    padding: 10px;
    font-size: 12px;
}

.myaccount-changenumber {
    padding-right: 20px;
    padding-left: 20px;
}

.pending {
    border: 3px dashed #e6b800;
    min-height: 50px;
    max-height: 75px;
    overflow: auto;
    overflow-y: hidden;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-warning {
    color: #9a6d4b;
    background-color: #fcf8e3;
    /* border-color: #faebcc !important; */
}

.imageBorder {
    border: 1px solid #88c656;
    border-radius: 4px;
    opacity: 0.7;
    transition: 0.3s;
    background-color: #FAFAFA !important;
    cursor: pointer;
}

    .imageBorder:hover {
        opacity: 1;
    }

.send-reminder {
    float: right;
    color: #88c656;
}

    .send-reminder:hover {
        text-decoration: underline;
        cursor: pointer;
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }

.imageSizing {
    width: 50%;
    padding: 4%;
    margin: 10%;
}

.complete-wizard-welcome-intro {
    display: inline-flex !important;
    margin: 0px 30px 15px 0px;
    padding: 0% 0% 0% 18%;
    width: 100%;
}

.margin-left-5 {
    margin-left: 5%;
}

/*Custom Checkbox START*/
/* Customize the label (the container) */
.consent-checkbox {
    display: block;
    position: relative;
    padding-left: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    /* Hide the browser's default checkbox */
    .consent-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 16px;
        width: 16px;
        top: 20px;
        left: 20px;
        z-index: 99;
    }

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid var(--control-border-color);
    border-radius: 4px !important;
}

/* On mouse-over, add a grey background color */
.consent-checkbox input:hover ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.consent-checkbox input:checked ~ .checkmark {
    background-color: var(--control-background-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.consent-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.consent-checkbox .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid var(--control-border-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/*Custom Checkbox END*/


/*K1 distribution start*/

.row-selected {
    background-color: #caedff !important;
}

.btn-white {
    background: #FAFAFA;
    border: 1px #e0e0e0 solid;
    color: #303641;
    margin-right: 2px;
    margin-bottom: 2px;
}

    .btn-white:hover {
        background: #ebebeb;
    }

.padT10 {
    padding-top: 10px;
}

.padT5 {
    padding-top: 5px;
}

.marL10 {
    margin-left: 10px;
}

.content-wrapper-2 {
    /*margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    max-width: 80% !important;*/
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}


    .content-wrapper-2 .content-select-k1-buttons {
        margin: 10% 0% 0% 0%;
        width: 100%;
        padding: 0% 15% 0% 0%;
        display: inline-flex;
        height: 220px;
    }

        .content-wrapper-2 .content-select-k1-buttons .k1-electronic-div {
            width: 50%;
            padding-left: 19%;
        }

            .content-wrapper-2 .content-select-k1-buttons .k1-electronic-div .imageBorderK1Electronic {
                color: #1a8fbf;
                margin: 55px;
            }

            .content-wrapper-2 .content-select-k1-buttons .k1-electronic-div .k1PageSpan1 {
                margin: 1% 0% 0% 1%;
                position: fixed;
                display: inline-flex;
            }

        .content-wrapper-2 .content-select-k1-buttons .k1-manual-div {
            width: 27%;
            margin: 0% 0% 0% 20%;
            padding: 0% 0% 0% 4%;
        }

            .content-wrapper-2 .content-select-k1-buttons .k1-manual-div .imageBorderK1Manual {
                color: #1a8fbf;
                margin: 45px;
            }

            .content-wrapper-2 .content-select-k1-buttons .k1-manual-div .k1PageSpan2 {
                position: fixed;
                margin: 1% 0% 0% 2%;
            }

.imageBorderK1Select {
    border: 2px solid #88c656;
    border-radius: 16px;
    opacity: 0.7;
    transition: 0.3s;
    background-color: #d8d8d8 !important;
}

    .imageBorderK1Select:hover {
        opacity: 1;
    }


.k1PartnerPopup .modal-header {
    padding: 15px;
}

.k1PartnerPopup .modal-title {
    color: var(--headerBgColor);
    font-size: 14px;
    font-weight: bold;
}

.k1PartnerPopup .modal-body {
    position: relative;
    padding: 15px;
    padding-left: 20px;
}




/* Pay Step - START */

.pay-content-left > div {
    /*padding: 10px;*/
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}

/*.pay-content-right > div {
    padding: 13px 20px 0px 20px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}*/

.pay-content-top > div {
    padding: 10px 21px 10px 20px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    /*width: 93%;*/
}

.pay-content-left .tax-payment-container {
    height: 100%;
    padding: 19px 21px 38px 20px;
}

#pay-tab-navbar {
    margin: 20px 0px;
    color: #88c656;
    font-weight: 700;
    width: auto;
    padding-left: 10px;
    font-size: 14px;
}

.pay-right-pointer {
    position: relative;
    background: var(--headerBgColor);
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    vertical-align: middle;
    color: white !important;
    box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
    width: 100% !important;
    border-radius: 6px;
}

.pay-arrow-right:before {
    content: "";
    position: absolute;
    right: -30px;
    top: -2px;
    border-top: 26px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 25px solid transparent;
    width: 31px;
}


.pay-arrow-right:after {
    content: "";
    position: absolute;
    right: -28px;
    top: 0px;
    border-top: 24px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 23px solid var(--headerBgColor);
    width: 32px;
    color: white;
    z-index: 9;
}

.pay-currencytext {
    text-align: right;
    padding-right: 15px;
    font-family: monospace;
}
/* Pay Step - END */

.k1PartnerPopup .modal-footer {
    padding: 15px;
    text-align: right;
}

.k1PartnerPopup-text {
    padding: 10px;
    font-size: 12px;
}

.displayFlex {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.displayFlex3 {
    flex: 3;
}

@media (min-width:311px) and (max-width: 800px) {
    .imageBorderK1Electronic {
        margin: 5px !important;
    }

    .mydownload-popup-icon {
        margin-top: -8px;
    }

    .imageBorderK1Select {
        width: 100px;
    }

    .imageBorderK1Manual {
        margin: 5px !important;
    }

    .k1PageSpan1 {
        margin: -18%;
        position: absolute !important;
        display: inline-table;
        padding: 5% 0% 0% 3%;
    }

    .k1PageSpan2 {
        position: absolute !important;
        margin: -17%;
        padding: 5% 0% 0% 10%;
        display: inline-table;
    }

    .displayFlex {
        flex-direction: column;
    }

    .distributeK1Tbl table, .distributeK1Tbl thead, .distributeK1Tbl tbody, .distributeK1Tbl th, .distributeK1Tbl td, .distributeK1Tbl tr {
        display: block;
    }

        .distributeK1Tbl thead tr :not(:first-child) {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

    .distributeK1Tbl tr {
        border: 1px solid #ccc;
    }

    .distributeK1Tbl td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

        .distributeK1Tbl td:before {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
        }

        .distributeK1Tbl td:nth-of-type(1):before {
            content: "";
        }

        .distributeK1Tbl td:nth-of-type(3):before {
            content: "Name";
        }

        .distributeK1Tbl td:nth-of-type(4):before {
            content: "Email";
        }

        .distributeK1Tbl td:nth-of-type(5):before {
            content: "Mobile";
        }

        .distributeK1Tbl td:nth-of-type(6):before {
            content: "Status";
        }

        .distributeK1Tbl td:nth-of-type(7):before {
            content: "Action";
        }
}
/*K1 distribution end*/

/* Download Page - START */

.inline-block-container {
    display: inline-block;
    vertical-align: top;
}

.download-title {
    font-size: 20px;
    font-weight: 600;
}

.download-list .title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
}

.download-list ul {
    list-style: none;
    padding: 0px;
    margin-top: 10px;
}

    .download-list ul li {
        height: 35px;
        color: #88c656;
        border-top: 1px solid #ccc;
        font-weight: bold;
        cursor: pointer;
        padding-top: 10px;
    }

        .download-list ul li:active {
            background-color: #0973ba;
            color: #fff;
        }

        .download-list ul li:hover {
            background-color: #f3f9ee;
        }

.download-container {
    min-height: 72vh;
    margin-top: 50px;
}


.padding-top-20 {
    padding-top: 20px !important;
}

.download-list ul li .download-image, .download-list ul li .info, .download-all-body ul li .download-image {
    float: right;
    padding: 1px;
    max-height: 16px;
    max-height: 16px;
}

.download-footer button {
    font-size: 14px;
    font-weight: bold;
    border-radius: 50px !important;
}

.download-all-body ul {
    list-style: none;
    padding: 0px;
    margin-top: 10px;
    width: 100%;
}

    .download-all-body ul li {
        color: #88c656;
        font-weight: bold;
        cursor: pointer;
        padding: 4px 0px;
        margin-bottom: 2px;
    }

        .download-all-body ul li:active {
            background-color: #0973ba;
            color: #fff;
        }

        .download-all-body ul li:hover {
            background-color: #f3f9ee;
        }

/* Download Page - END */
.information-popup {
    position: absolute;
    top: 45%;
    left: 40%;
}

.btn-primary-extended {
    background-color: #88c656 !important;
    border-radius: 50px !important;
    min-width: 150px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border: 1px solid #88c656 !important;
    margin-right: 15px;
}

.complete-btn-primary-extended {
    color: #fff;
    border: 1px solid #017cff !important;
    background-color: #017cff !important;
    border-radius: 50px !important;
    min-width: 150px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin-right: 15px;
}

.btn-danger-extended {
    background-color: #CC4A43 !important;
    border-radius: 50px !important;
    min-width: 150px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border: 1px solid #CC4A43 !important;
    margin-right: 15px;
}

.btn-default-extended {
    border-radius: 50px !important;
    min-width: 150px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border: 1px solid #dfdfdf !important;
    margin-right: 15px;
}

footer {
    height: 50px;
    background: white !important;
    border: 1px solid #bfbfbf;
    padding: 5px;
}

.invalid-container {
    background-color: #F0F0F0;
    height: 100%;
}

.invalid-header {
    background-color: #0973ba;
    height: 52px;
    padding: 6px;
}

.error-page {
    padding-left: 16% !important;
    padding-right: 16% !important;
    padding-top: 30px !important;
    height: 90vh;
    overflow: auto;
}

.error-page-container {
    border-radius: 6px;
    padding: 22% 10%;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
}

    .error-page-container .message {
        font-size: 22px;
        text-align: center;
    }

    .error-page-container .otp-error-message {
        font-size: 20px;
        text-align: center;
    }

.NotesHeader {
    margin: 5px 5px 5px 5px;
    font-weight: 600;
    font-size: 14px;
}

.NotesSection {
    margin: 5px 5px 5px 5px;
    font-size: 11px;
}

.btn-Notes-Add {
    background-color: #88c656 !important;
    border-radius: 50px !important;
    min-width: 60px !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    border: 1px solid #88c656 !important;
    margin-right: 10px;
    margin-top: 5px;
    padding: 0.3rem 0.7rem;
}

.delete-icon {
    color: #c7441c;
    font-size: 15px;
    cursor: pointer;
    margin-top: 10px;
}

.NotesText {
    font-size: 13px;
    margin-left: 6px;
    width: -webkit-fill-available;
    margin-right: 10px;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.right-view-panel-container {
    gap: 4px;
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
    justify-content: space-between;
}

/*Source Document Upload*/

.OrgSourceDocument {
    min-height: 7rem;
    max-height: 45vh;
    overflow-y: auto;
}

@media only screen and (min-height : 544px) and (max-width : 1206px) and (orientation: landscape) {
    .OrgSourceDocument {
        min-height: 7rem;
        max-height: 34vh;
        overflow-y: auto;
    }
}


.BookMarkFilterSelect {
    float: right;
    margin-left: 10px !important;
    width: 140px !important;
    text-align: left !important;
}

.BookMarkFilterLable {
    padding-left: 5px;
    float: left;
    padding-top: 0px;
    margin-top: 7px;
    margin-left: 15px
}

.FilterMessage .modal-sm {
    max-width: 450px;
}

    .FilterMessage .modal-sm modal-body {
        padding-top: 22px;
    }

    .FilterMessage .modal-sm .modal-footer {
        padding: 0px !important;
        height: 50px !important;
    }

        .FilterMessage .modal-sm .modal-footer .btn {
            margin-right: 15px;
            max-width: 75px !important;
            min-width: 75px !important;
            width: 75px !important;
        }

.source-document-header {
    margin: 7px 5px 5px 5px;
    font-weight: 600;
    font-size: 14px;
    color: #007ee6;
    text-align: left;
}

.source-document-section {
    margin: 5px 5px 7px 5px;
    font-size: 11px;
    text-align: left;
    max-height: calc(100vh - 37rem);
    min-height: 3rem;
    overflow-y: auto;
}

.modal.source-document-upload-model .modal-dialog {
    min-width: 65%;
}

.modal.source-document-upload-model .modal-title {
    color: #007ee6;
}

.source-document-upload-box {
    text-align: center;
    margin: 2px;
    font-size: 12px;
}

.source-document-upload-button {
    background-color: #88c656 !important;
    border-radius: 50px !important;
    min-width: 60px !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    border: 1px solid #88c656 !important;
    margin-bottom: 0.6rem;
}

.source-document-delete-icon {
    color: #c7441c;
    font-size: 15px;
    cursor: pointer;
}

.source-document-failed-icon {
    color: red;
    font-size: large;
    cursor: pointer;
}

.bookmark-list-item-list-conatiner {
    overflow-x: hidden;
}

#left-panel > div {
    overflow-x: hidden;
}

.div-flex {
    display: flex;
}

.padT07 {
    padding-top: 7px;
}

.magic-checkbox + label {
    padding-left: 5px;
}

.magic-checkbox {
    width: 15px;
    height: 15px;
}

.awesome-pdf-viewer-loader.visible {
    background-color: unset !important;
}

/* welcome page Css*/


.cp-welcome-page {
    width: 75%;
    margin: auto;
    max-width: 430px;
    margin-top: 4%;
}

.cp-welcome-page-container {
    background-image: url(../images/envelope.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 500px;
}

.cp-company-info-container {
    height: 20%;
    width: 50%;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 1px 3px #000 inset;
    -moz-box-shadow: 0 0 1px 3px #000 inset;
    box-shadow: 0 0 1px 3px #cbcbcb6b inset;
    box-shadow: inset 0 -9px 8px -12px #000000;
    box-shadow: inset 1 -9px 8px -12px #000000;
    box-shadow: inset 2px 3px 5px 2px #b7b7b796;
    margin: 12%;
    position: absolute;
    border: 1px solid #b7b7b796;
    padding: 10px;
}

.cp-prepared-by-img {
    max-width: 100%;
    max-height: 90%;
    margin-top: 12px
}

.cp-prepared-by-company-name {
    font-size: 15px;
    font-weight: 600;
    text-align: left;
}

.cp-company-address-container {
    font-weight: 500;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
}

.cp-tax-client-info-container {
    height: 25%;
    width: 70%;
    border-radius: 5px;
    background-color: #8bc656;
    margin: 15%;
    position: absolute;
    margin-top: 50%;
    display: flex;
    user-select: none;
}

.cp-tax-client-info-text {
    height: 85%;
    width: 86%;
    background-color: white;
    margin: 10px 10px 10px 10px;
    -webkit-box-shadow: 0 0 1px 3px #000 inset;
    -moz-box-shadow: 0 0 1px 3px #000 inset;
    box-shadow: 0 0 1px 3px #cbcbcb6b inset;
    box-shadow: inset 0 -9px 8px -12px #000000;
    box-shadow: inset 1 -9px 8px -12px #000000;
    box-shadow: inset 2px 3px 5px 2px #b7b7b796;
    border-radius: 10px;
    /* margin: 10px 0px 0px 15px; */
    padding: 13px;
    text-align: center;
    border: 1px solid #b7b7b7eb;
}

.cp-tax-client-info-label {
    margin: auto auto auto 10px;
    color: white;
    font-weight: 600;
}

.cp-tax-client-name {
    margin-top: 5px;
    font-weight: bold;
}

.cp-tax-year-header {
    /*font-weight: 600;*/
}

.cp-tax-client-name {
    margin-top: 5px;
    font-weight: bold;
}

.cp-btn-organizer-continue {
    margin: 0px;
    position: absolute;
    top: 80%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 35%;
    background-color: yellow;
    width: 150px;
    font-weight: 600;
    border-radius: 50px !important;
}

@media (min-width: 768px) {
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}


@media (min-width: 576px) {
    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

/* welcome page css end*/



/*OTP page start*/


.auth-otp-page {
    padding-left: 16% !important;
    padding-right: 16% !important;
    padding-top: 30px !important;
    height: 90vh;
    overflow: auto;
}

.auth-otp-page-container {
    border-radius: 6px;
    padding: 12% 10%;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
}

    .auth-otp-page-container input {
        border: 1px solid #8bc656;
        border-radius: 5px;
        background-color: #f3f9ee;
        width: 80px;
        height: 40px;
        color: #000;
        padding: 5px;
        font-weight: bold;
        font-size: 16px;
        margin: 0 4px;
    }

    .auth-otp-page-container input {
        width: 160px;
    }

        .auth-otp-page-container input::-webkit-input-placeholder {
            font-weight: normal;
        }

        .auth-otp-page-container input::-moz-placeholder {
            font-weight: normal;
        }

        .auth-otp-page-container input:-ms-input-placeholder {
            font-weight: normal;
        }

        .auth-otp-page-container input:-o-input-placeholder {
            font-weight: normal;
        }

        .auth-otp-page-container input[type="radio"] {
            width: 70px;
            height: 20px;
        }


/*OTP page end*/

/* Cover Page Header Styles start */

.cp-app-header {
    height: 52px;
    background-color: #45b2e8;
    /* background-color: var(--headerBgColor); */
    padding: 6px;
    z-index: 2;
    position: relative;
    display: flex;
}

.cp-header-left-container {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: auto;
    padding-left: 6%;
}

.cp-header-right-container {
    margin-left: auto;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-right: 6%;
    min-width: 175px;
}

.cp-header-mydownload, .cp-header-contact-info, .cp-header-home, .cp-header-logout, .cp-header-account, .cp-header-taxYear, .cp-header-taxReturn,
.cp-header-back-to-controller {
    float: right;
    padding: 12px;
    color: white;
    cursor: pointer;
    margin-left: 15px;
}

    .cp-header-contact-info a, .cp-header-home a {
        color: white;
    }

        .cp-header-contact-info a svg, .cp-header-home a svg {
            max-width: 16px;
            max-height: 16px;
            margin-right: 5px;
        }

    .cp-header-contact-info:hover, .cp-header-logout:hover, .cp-header-home:hover {
        text-decoration: underline;
    }

.cp-popover {
    border: 1px solid #0973ba;
}

.cp-popover-header {
    background-color: #0973ba;
    color: white;
    font-size: 12px;
    font-weight: bold;
    margin: 1px;
}

.cp-mail {
    font-size: 14px;
    color: #88c656;
    font-weight: bold;
}

.cp-mail a:hover {
        cursor: pointer;
}
header .cp-company-name {
    color: white;
    font-size: 28px;
    margin-left: 10px;
}

.prepared-by-img {
    max-width: 100%;
    max-height: 90%;
}

/* Welcome page media querry start*/
@media only screen and (max-width: 1038px) {
    .cp-welcome-page {
        width: 90%;
        max-width: 950px;
        margin-top: 50px;
    }

    .cp-welcome-page-container {
        height: calc(100vh - 200px);
        background-size: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    }

    .cp-company-info-container {
        position: unset;
        margin-top: 15%;
    }

        .cp-company-info-container img {
            height: 100%;
        }

    .cp-tax-client-info-container {
        height: 25%;
        width: 70%;
        margin: 15%;
        position: unset;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
    }

    .cp-tax-client-info-text {
        margin: auto 20px auto auto;
        padding-top: 6%;
        /* height: 75%; */
    }

    .cp-tax-client-info-label {
        margin: auto auto auto 15px;
        font-size: 22px;
    }

    .cp-tax-year-header {
        font-size: 22px;
    }

    .cp-tax-client-name {
        /* margin-bottom: 30px; */
        font-size: 24px;
        margin-top: 30px;
    }

    .cp-btn-organizer-continue {
        position: unset;
        margin-left: auto;
        margin-right: auto;
        font-size: 24px;
        width: 180px;
    }
}

@media only screen and (max-width: 769px) {
    .cp-welcome-page {
        width: 85%;
        max-width: 700px;
        margin-top: 5%;
    }

    .cp-welcome-page-container {
        height: calc(100vh - 200px);
        background-size: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 80px;
    }

    .cp-company-info-container {
        position: unset;
        margin-top: 15%;
        margin: 10%;
    }

        .cp-company-info-container img {
            height: 100%;
        }

    .cp-tax-client-info-container {
        height: 25%;
        width: 70%;
        /* margin: 15%; */

        position: unset;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
    }

    .cp-tax-client-info-text {
        margin: auto 20px auto auto;
        padding-top: 6%;
    }

    .cp-tax-client-info-label {
        margin: auto auto auto 10px;
        font-size: 18px;
    }

    .cp-tax-year-header {
        font-size: 18px;
    }

    .cp-tax-client-name {
        /* margin-bottom: 30px; */
        font-size: 20px;
        margin-top: 30px;
    }

    .cp-btn-organizer-continue {
        position: unset;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        width: 160px;
    }
}
/* Tab s7 Firefox landscape */
@media only screen and (min-height : 480px) and (max-width : 1206px) and (orientation: landscape) {
    .cp-welcome-page {
        width: 80%;
        max-width: 315px;
        margin-top: 2%;
    }

    .cp-welcome-page-container {
        height: calc(100vh - 200px);
    }

    .cp-tax-client-info-label {
        margin: auto 8px auto 8px;
        font-size: 12px;
    }

    .cp-tax-client-info-text {
        margin: auto 14px auto auto;
        padding-top: 4%;
    }

    .cp-tax-client-info-container {
        margin: 15%;
        margin-top: 0px;
        height: 40%;
    }

    .cp-tax-year-header {
        font-size: 12px;
    }

    .cp-tax-client-name {
        font-size: 14px;
        margin-top: 8px;
    }

    .cp-btn-organizer-continue {
        position: unset;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        width: 150px;
    }
}

@media only screen and (min-height : 544px) and (max-width : 1206px) and (orientation: landscape) {
    .cp-welcome-page {
        width: 80%;
        max-width: 400px;
        margin-top: 2%;
    }

    .cp-welcome-page-container {
        height: calc(100vh - 150px);
        background-size: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    }

    .cp-company-info-container {
        position: unset;
        margin-top: 15%;
    }

        .cp-company-info-container img {
            height: 100%;
        }

    .cp-tax-client-info-container {
        height: 25%;
        width: 70%;
        margin: 15%;
        position: unset;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
    }

    .cp-tax-client-info-text {
        margin: auto 10px auto auto;
        padding-top: 6%;
    }

    .cp-tax-client-info-label {
        margin: auto 10px auto 10px;
        font-size: 14px;
    }

    .cp-tax-year-header {
        font-size: 14px;
    }

    .cp-tax-client-name {
        font-size: 16px;
        margin-top: 10px;
    }

    .cp-btn-organizer-continue {
        position: unset;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        width: 180px;
    }
}
/* Ipad pro 11 Landscape */
@media only screen and (min-height : 830px) and (max-width : 1206px) and (orientation: landscape) {
    .cp-welcome-page {
        width: 80%;
        max-width: 500px;
        margin-top: 2%;
    }

    footer {
        height: 66px;
    }

    .mt-6 {
        margin-top: 6px;
    }

    .TaxPayerNotes {
        top: unset;
        bottom: 0px;
    }

    .side-panel {
        height: calc(100% - 148px) !important;
    }
}

@media only screen and (min-height : 950px) and (max-width : 1369px) and (orientation: landscape) {
    .cp-welcome-page {
        width: 80%;
        max-width: 600px;
        margin-top: 10%;
    }

    .cp-welcome-page-container {
        height: calc(100vh - 200px);
        background-size: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    }

    .cp-company-info-container {
        position: unset;
        margin-top: 15%;
    }

        .cp-company-info-container img {
            height: 100%;
        }

    .cp-tax-client-info-container {
        height: 25%;
        width: 70%;
        margin: 15%;
        position: unset;
        margin-top: 5%;
        margin-left: auto;
        margin-right: auto;
    }

    .cp-tax-client-info-text {
        margin: auto 10px auto auto;
        padding-top: 6%;
    }

    .cp-tax-client-info-label {
        margin: auto 10px auto 10px;
        font-size: 16px;
    }

    .cp-tax-year-header {
        font-size: 16px;
    }

    .cp-tax-client-name {
        font-size: 18px;
        margin-top: 20px;
    }

    .cp-btn-organizer-continue {
        position: unset;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        width: 180px;
    }
}
/* Welcome page media querry end*/

/* Request code screen start */
@media only screen and (max-width: 1038px) {

    .auth-otp-page {
        margin-top: 10%;
        padding-left: 8% !important;
        padding-right: 8% !important;
        padding-top: 30px !important;
        font-size: 22px;
    }

        .auth-otp-page p {
            font-size: 22px;
            line-height: normal;
        }

        .auth-otp-page button {
            font-size: 22px;
        }

    .auth-otp-page-container {
        padding: 18% 8%;
    }

        .auth-otp-page-container h5 {
            font-size: 26px;
            margin-bottom: 10px;
        }

        .auth-otp-page-container .margin-top-50 {
            margin-top: 60px;
        }

        .auth-otp-page-container .margin-top-10 {
            margin-top: 20px;
        }

    .access-code-expiry-info {
        margin-left: 0 !important;
        display: block;
        margin-top: 5%;
    }
}


@media only screen and (max-width: 769px) {
    .auth-otp-page {
        margin-top: 5%;
        padding-left: 8% !important;
        padding-right: 8% !important;
        padding-top: 30px !important;
        font-size: 18px;
    }

        .auth-otp-page p {
            font-size: 18px;
            line-height: normal;
        }

        .auth-otp-page button {
            font-size: 18px;
        }

    .auth-otp-page-container {
        padding: 18% 8%;
    }

        .auth-otp-page-container h5 {
            font-size: 24px;
            margin-bottom: 10px;
        }

        .auth-otp-page-container .margin-top-50 {
            margin-top: 50px;
        }

        .auth-otp-page-container .margin-top-10 {
            margin-top: 10px;
        }

    .access-code-expiry-info {
        margin-left: 0 !important;
        display: block;
        margin-top: 5%;
    }
}
/* Tab s7 Firefox landscape */
@media only screen and (min-height : 480px) and (max-width : 1206px) and (orientation: landscape) {
    .auth-otp-page {
        margin-top: 2%;
        padding-left: 8% !important;
        padding-right: 8% !important;
        padding-top: 20px !important;
        font-size: 14px;
    }

        .auth-otp-page p {
            font-size: 14px;
            line-height: normal;
        }

        .auth-otp-page button {
            font-size: 14px;
        }

    .auth-otp-page-container {
        padding: 6% 6%;
    }

        .auth-otp-page-container h5 {
            font-size: 20px;
            margin-bottom: 8px;
        }

        .auth-otp-page-container .margin-top-50 {
            margin-top: 30px;
        }

        .auth-otp-page-container .margin-top-10 {
            margin-top: 5px;
        }

    .access-code-expiry-info {
        margin-left: 0 !important;
        display: block;
        margin-top: 3%;
    }

    .organizer-welcome-page-container {
        padding-top: 10px;
    }
}

@media only screen and (min-height : 544px) and (max-width : 1206px) and (orientation: landscape) {

    .auth-otp-page {
        margin-top: 2%;
        padding-left: 8% !important;
        padding-right: 8% !important;
        padding-top: 20px !important;
        font-size: 14px;
    }

        .auth-otp-page p {
            font-size: 14px;
            line-height: normal;
        }

        .auth-otp-page button {
            font-size: 14px;
        }

    .auth-otp-page-container {
        padding: 8% 6%;
    }

        .auth-otp-page-container h5 {
            font-size: 20px;
            margin-bottom: 8px;
        }

        .auth-otp-page-container .margin-top-50 {
            margin-top: 40px;
        }

        .auth-otp-page-container .margin-top-10 {
            margin-top: 5px;
        }

    .access-code-expiry-info {
        margin-left: 0 !important;
        display: block;
        margin-top: 3%;
    }
}


@media only screen and (min-height : 950px) and (max-width : 1369px) and (orientation: landscape) {


    .auth-otp-page {
        margin-top: 4%;
        padding-left: 8% !important;
        padding-right: 8% !important;
        padding-top: 30px !important;
        font-size: 22px;
    }

        .auth-otp-page p {
            font-size: 22px;
            line-height: normal;
        }

        .auth-otp-page button {
            font-size: 22px;
        }

    .auth-otp-page-container {
        padding: 10% 8%;
    }

        .auth-otp-page-container h5 {
            font-size: 26px;
            margin-bottom: 10px;
        }

        .auth-otp-page-container .margin-top-50 {
            margin-top: 60px;
        }

        .auth-otp-page-container .margin-top-10 {
            margin-top: 20px;
        }

    .access-code-expiry-info {
        margin-left: 0 !important;
        display: block;
        margin-top: 5%;
    }
}

/* Request code screen end */

/* Source document Upload UI bug fix */
@media only screen and (max-width: 1038px) {
    .offset-md-1 {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
}

.footer-navigation-buttons-container {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 52%;
    height: 100%;
    gap: 30px
}

.footer-navigation-button {
    width: 30px;
    height: 30px;
    color: #0873ba;
    font-size: 30px;
}

    .footer-navigation-button:hover {
        cursor: pointer;
        opacity: 0.8;
    }

.footer-navigation-button-disabled {
    cursor: not-allowed !important;
    color: grey;
}

.parentTreeNode {
    font-size: 14px;
}

.childTreeNode {
    font-size: 13px;
}

/* Source document Upload UI bug fix */
.offset-md-1 {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.pdfViewer .page select.document-control {
    text-overflow: ellipsis;
}

@media only screen and (max-width: 1224px) {    
    .main .page-viewer {
        margin-left: 0px !important;
    }

    .file-preview-modal  .page-viewer  {
        margin-left: 0px !important;
    }

    .left-panel.side-panel.collapsed ~ .page-viewer {
        margin-left: 0 !important;
    }

    .side-panel {
        height: calc(100% - 148px) !important;
        width: 325px;
    } 
}

@media only screen and (max-width: 769px) {

    .side-panel {
        width: 200px;
    }
}

.cp-tax-client-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.cursor-default {
    cursor: default;
}
.source-document-upload-warning-msg{
    margin-bottom: 6px;
    font-weight: 500;
}
.source-document-upload-model-imp-msg{
    padding-top: 20px;
    font-weight: 500;
    font-size: medium;
    padding-bottom: 8px;
}

.source-document-upload-model-footer{
    padding-top :2px;
    padding-left: initial;

}

/* --------Custom questions -Start------ */
.cq-tab-wrapper{
    max-height: calc(100vh - 102px);
    overflow: auto;
}
.custom-questions-tab-template-container{
    width: 60%;
    margin: 1.5% auto 0 auto;
}
@media only screen and (max-width : 1206px){
    .custom-questions-tab-template-container{
        width: 80%;
    }
}
@media only screen and (max-width: 1038px){
    .cq-tab-wrapper{
        max-height: calc(100vh - 200px);
    }
}
@media only screen and (min-height : 544px) and (max-width : 1369px) and (orientation: landscape) {
    .cq-tab-wrapper{
        max-height: calc(100vh - 200px);
    }
}
.questionnaire-header-container {
    position: relative;
    padding: 25px 25px 10px 35px;
    border-top: 5px solid #0973ba;
    background: white;
    box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.3);
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 10;
    top: 0;
}
.title-description-container{
    display: flex;
    flex-direction: column;
    margin-right: 1.5%;
}
.progress-bar-wrapper{
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.progress-bar-wrapper svg{
    width: 100px;
    height: 100px;
    transform: rotate(-90deg);
    position: absolute;
}
.progress-bar-wrapper svg circle{
    fill: none;
    stroke: #f0f0f0;
    stroke-width: 5;
    stroke-linecap: round;
}
.progress-bar-wrapper svg circle:last-of-type {
    stroke-dasharray: 282px;
    stroke: #669440; 
    transition: all 1s ease-in;
  }
.number-wrapper{
    width: 75px;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #C4C6C8;
    border-radius: 50%;

}
.number-wrapper >span:first-child {
    font-size: 24px;
    font-weight: 600;

}
.number-wrapper >span:last-child {
    font-size: 12px;
    color: #6B7075;
}

.custom-questions-tab-template-container .title {
    color: rgba(9, 115, 186, 1);
    font-size: 28px;
    font-weight: 500;
    width: 100%;
    min-height: 40px;
    padding: 0px;
    border: none;
    border-bottom: none !important;
    resize: none;
    text-align: justify;
}

.custom-questions-tab-template-container .description {
    font-size: 16px;
    font-style: italic;
    width: 100%;
    height: 100% !important;
    min-height: 40px;
    padding: 5px 0px;
    vertical-align: bottom;
    border: none;
    border-bottom: none !important;
    color: #212529;
    resize: none;
    outline: none;
    text-align: justify;
}

.preview-section-container {
	padding: 1.5%;
	margin-top: 1.5%;
	background-color: white;
	color: #212529;
    box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.3);
    border-radius: 2px;
}

.preview-section-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 0.25px solid rgba(0, 0, 0, 0.2);
	margin: 0 0 10px 0;
    margin-left: 1.5%;
}

.preview-section-container > p {
	font-size: 20px;
	color: #404346;
	padding-bottom: 1.25%;
	border-bottom: 0.25px solid rgba(0, 0, 0, 0.5);
	margin-bottom: 0;
}

.preview-section-container .preview-section-header > p {
    font-size: 20px;
    color: #0973ba;
    padding-bottom: 1%;
    margin-bottom: 0;
    word-break: break-word;
}

.section-delete-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin-left: 5%;
}

.section-delete-button:disabled {
	opacity: 0.5;
}

.preview-section-container .question-preview-container {
	margin: 0;
}

.question-preview-container label {
	font-size: 18px;
	font-weight: 600;
	width: 100%;
}
.question-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 100%;
    position: relative;
}
.active-question{
    background-color: rgba(230, 235, 240, 0.3);
    transition: all 0.5s;
}
.active-question::before{
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 5px;
    background-color: #0973BA;
    border-radius: 4px;
}
.question {
	width: 100%;
	overflow: auto;
    padding: 1.5%;
}

.question-preview-container label > span:first-child {
	color: #0973ba;
	margin-right: 12px;
}

.question-preview-container .answer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
    margin-left: 30px;
}

.question-preview-container textarea {
	border: 1px solid #898d91;
	border-radius: 2px !important;
	font-size: 16px;
	padding: 7px 13px;
	max-height: 20vh;
    box-shadow: none;
}

.question-preview-container .invalid-input {
	border-color: #cc4a43 !important;
	box-shadow: none;
}

.question-preview-container .non-editable {
	resize: none;
	outline: none;
	cursor: none;
	pointer-events: none;
}

.question-preview-container .error-text {
	color: #cc4a43 !important;
	font-size: 14px;
	margin-top: 8px;
	margin-bottom: 0;
}
.cq-error-red {
    color: rgba(204, 74, 67, 1) !important;
}
@media (max-width: 1920px) {
	.preview-title {
		font-size: 30px;
	}
}
@media (min-width: 1920px) {
	.preview-title {
		font-size: 40px;
	}
}
.preview-title {
	color: rgba(9, 115, 186, 1);
	font-weight: 500;
	width: 100%;
	height: 100%;
	min-height: 40px;
	padding: 0px;
	border: none;
	border-bottom: none !important;
	resize: none;
	word-wrap: break-word;
}


.multi-choice-preview-wrapper .form-check label {
    font-size: 16px;
    margin-left: 24px;
    font-weight: 400;
    max-width: 100%;
    margin-bottom: 0;
    pointer-events: none;
}

.multi-choice-preview-wrapper .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    padding-left: 0;
}

    .multi-choice-preview-wrapper .form-check input {
        margin: 0;
        cursor: pointer;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 3px;
    }

.multi-choice-preview-wrapper .error-radio input {
    appearance: initial;
    border: 1px solid #cc4a43;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    padding-left: 13px;
}
.multi-choice-preview-wrapper .error-radio label {
    color: #cc4a43 !important;
    display: ruby;
}
.multi-choice-preview-wrapper .form-check:last-child {
    margin-bottom: 0;
}
.answer-wrapper {
    margin-top: 10px;
    margin-left: 28px;
}
.follow-up-answer{
    margin-left: 15px;
}
.answer-wrapper .form-check-input {
    width: 25px;
    height: 25px;
    cursor: pointer !important;
}
.answer-wrapper .taxpayer-radio,
.spouse-radio,
.yesno-error-radio {
    height: 25px;
    min-width: 72px;
}
.multi-choice-preview-wrapper .height-unset{
    height: unset;
}
.answer-wrapper .form-check-input:checked {
    accent-color: #0973ba;
}

.answer-wrapper .form-check-label {
    padding: 0px 8px 0px 6px;
    font-size: 16px;
    font-weight: 400;
    pointer-events: none;
}
.answer-wrapper .yesno-error-radio input {
    appearance: initial;
    border: 1px solid #cc4a43;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    padding-left: 13px;
}
.answer-wrapper .yesno-error-radio label {
    color: #cc4a43 !important;
    display: ruby;
}
.answer-wrapper .taxpayer-radio input:checked {
    accent-color: #542D87;
}
    
.answer-wrapper .spouse-radio input:checked {
    accent-color: #5C853A;
}
.padding-24 {
    padding-left: 24px;
}

.padding-12 {
    padding-left: 12px;
}
.sub-question-question-border {
    position: relative;
}
.sub-question-question-border:before {
    content: "";
    width: 1px;
    height: 58px;
    background: #0973ba;
    position: absolute;
    left: -25px;
}
.sub-question-label{
    margin-top: 13px;
    position: relative;
    margin-bottom: 0;
}
.yes-no-followup-wrapper > label {
    position: relative;
    margin-bottom: 0;
}
.yes-no-followup-wrapper > label:first-child:before {
    content: "";
    width: 1px;
    height: 10px;
    transform: rotate(90deg);
    background: #0973ba;
    position: absolute;
    left: -19px;
    top: 10px;
}
.followup-question-wrapper {
    margin-top: 18px;
}

/* --------Custom questions -End-------- */

/* --------Validation Modal -Start-------- */
.validation-container{
margin-top: 10px;
}
.validation-container span{
font-size: 14px;
font-weight: bold;
}
.validation-container > span{
display: list-item;
margin-left: 1rem;
}
.questions-conatiner{
    color: grey;
    margin-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    
}
.questions-conatiner > span{
    margin-right: 2px;
    font-weight: normal;
}
/* --------Validation Modal -End-------- */
/*Spouse Email Confirmation-Start*/
.paragraph-spouse-emailaddress{
    margin-bottom: 0;
}
.label-spouse-emailaddress-required-indictor{
    color: red;
}
.textbox-spouse-emailaddress{
    width: inherit;
}
/*Spouse Email Confirmation-End*/

