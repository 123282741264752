/* Tablet Devices */


@media (max-width:768px) {

    .step-layout {
        margin: 0px !important;
    }

    .custom-button {
        width: 160px !important;
    }

    .custom-button-text-container {
        font-size: 10px !important;
    }

    .pay-content-right .pay-display-box .pay-button {
        margin: 15px 0px 0px 15px !important;
    }

    .step-layout.with-footer {
        height: calc(100vh - 75px) !important;
    }

    .step-layout.with-footer {
        height: calc(100vh - 75px) !important;
    }

    .complete-wizard-welcome-intro > .margin-left-5 {
        margin-left: 0px;
    }

    .content-top, .content-left, .content-wrapper-full {
        margin: 0px;
        padding: 0px;
        border-right: 1px solid #c1c1c1;
        height: 100%;
    }

    .margin-top-20 {
        margin-top: 0px !important;
    }

    .completed-summary-step-container {
        height: 100vh !important
    }

    .content-right, .content-wrapper-full {
        height: 100%;
    }

    .step-layout-header {
        font-size: 14px;
    }

    #awesome-pdf-viewer > .main > .right-panel {
        width: 400px;
    }

	.myaccount .modal-content {
		width: 100%;
		margin-left: 0%;
	}
}