/* Tablet Devices */


@media(min-width:480px) and (max-width:768px) {

    .step-layout-left-panel-body {
        padding: 0px !important;
    }

    .pay-content-right .pay-display-box {
        width: 155px !important;
    }

    .otp-error-message {
        font-size: 26px !important;
        line-height: 46px !important;
    }
}

@media(min-width:768px) and (max-width:1038px) {

    .step-layout-left-panel-body {
        padding: 0px !important;
    }

    .pay-content-right .pay-display-box {
        width: 155px !important;
    }

    .otp-error-message {
        font-size: 28px !important;
        line-height: 50px !important;
    }
}
@media only screen and  (min-height : 949px) and (max-width : 1369px) and (orientation: landscape){
    .step-layout-left-panel-body {
        padding: 0px !important;
    }

    .pay-content-right .pay-display-box {
        width: 155px !important;
    }

    .otp-error-message {
        font-size: 28px !important;
        line-height: 50px !important;
    }
}